// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gh-card{
    text-align: justify;
}

.gh-card-container:nth-child(even){
    padding-left: 25px!important;
}
.gh-card-container:nth-child(odd){
    padding-right: 25px!important;
}

.gh-card i:after{
    content:" ";
}`, "",{"version":3,"sources":["webpack://./src/components/Cardset.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;AAChC;AACA;IACI,6BAA6B;AACjC;;AAEA;IACI,WAAW;AACf","sourcesContent":[".gh-card{\n    text-align: justify;\n}\n\n.gh-card-container:nth-child(even){\n    padding-left: 25px!important;\n}\n.gh-card-container:nth-child(odd){\n    padding-right: 25px!important;\n}\n\n.gh-card i:after{\n    content:\" \";\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
