import './App.css';
import Banner from './components/Banner';
import About from './components/About';
import BodyFooter from './components/BodyFooter';

function App() {
  return (
    <>
      <div className="App">
        <Banner />
        <About />
        <BodyFooter />
      </div>
    </>
  );
}

export default App;
