// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.img img {
    height: 300px;
    display: inline-block;
}

.rounded-img img {
    border-radius: 100%;
}

section {
    padding: 10px;
    text-align: justify;
    background-color: white;
}

.section-gray {
    background-color: #eaeaea;
}

section div {
    padding: 30px;
    ;
}`, "",{"version":3,"sources":["webpack://./src/components/BodySection.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;;AAEjB","sourcesContent":["\n.img img {\n    height: 300px;\n    display: inline-block;\n}\n\n.rounded-img img {\n    border-radius: 100%;\n}\n\nsection {\n    padding: 10px;\n    text-align: justify;\n    background-color: white;\n}\n\n.section-gray {\n    background-color: #eaeaea;\n}\n\nsection div {\n    padding: 30px;\n    ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
