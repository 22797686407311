import "./BodySection.css"
import URLButton from "./URLButton"

const BodySection = (props) => {
    let text = (<p>
        {props.txt}
    </p>);

    if (props.linkedPhrase) {
        const parts = props.txt.split(props.linkedPhrase);
        text = (<p>
            {parts[0]}
            <a href={props.linkedtxturl} target="_blank" rel="noopener noreferrer">{props.linkedPhrase}</a>
            {parts[1]}
        </p>)
    }


    return (
        <section className={"section " + (props.gray ? "section-gray" : "")}>
            <div className={"col-sm-10 offset-sm-1 d-flex flex-column " + (props.reverse ? "flex-sm-row-reverse" : "flex-sm-row") +
                " justify-content-center align-items-center"}
                id={props.idname}
            >

                <div className={"img " + (props.round ? "rounded-img" : "")}>
                    <img src={props.img} alt="My Image" />
                </div>
                <div>
                    <h2>{props.title}</h2>
                    {text}
                    <URLButton url={props.btnurl} txt={props.btntxt} />
                </div>
            </div>
        </section >
    )
}

export default BodySection