import ProjectCard from "./ProjectCard";
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import "./Cardset.css";

function CardSet(props) {
    const API = "https://api.github.com";
    const username = "triolm"
    const specficReposAPI = `${API}/repos/${username}`;
    const specfic = [ "mr-dice","newtons-fractal","n-body", "raytracer"]


    const [projectsArray, setProjectsArray] = useState([]);

    const fetchRepos = async () => {
        let repoList = [];
        try {
            try {
                for (let repoName of specfic) {
                    const response = await axios.get(`${specficReposAPI}/${repoName}`);
                    repoList.push(response.data);
                }
            } catch (error) {
                console.error(error.message);
            }
            setProjectsArray(repoList);
        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        fetchRepos();
    }, []);


    return (
        <div className="section-gray">
            <h2 className="py-3 pt-5" id="projects">Projects</h2>
            <div className="d-flex flex-wrap col-sm-8 offset-sm-2 pt-3">
                {projectsArray.map((project, index) => (
                    <ProjectCard
                        key={`project-card-${index}`}
                        id={`project-card-${index}`}
                        value={project}
                    />))}
            </div>
        </div>
    );
}

export default CardSet;