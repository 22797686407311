import "./Banner.scss"
import BannerNav from "./BannerNav";

function Banner() {
    var particles = [];
    for (var i = 0; i < 100; i++) {
        particles.push(<div className='circle-container' key={i}><div className="circle"></div></div>);
    }

    return (
        <header id="home">
            <div className="banner-container">
                {particles}
            </div>
            <div className="header-foreground">
                <BannerNav />
                <div className="title-text d-flex flex-column justify-content-center align-items-center">
                    <h1 className="pt-5">Hi, I'm Marisa!</h1>
                    <a className="text-white pt-3" href="https://github.com/triolm">
                        <i class="bi bi-github"></i>
                    </a>
                </div>
                <div className="p-5">
                    <a href="#about" className="text-white">
                        <i class="bi bi-caret-down"></i>
                    </a>
                </div>
            </div>
        </header >
    );
}

export default Banner;