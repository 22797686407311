// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.btn{
    border: 1.5px solid #021027 !important;
    border-radius: 5px;
    color:#021027;
}

a.btn:hover{
    background-color: #021027;
    color:white;
}`, "",{"version":3,"sources":["webpack://./src/components/URLButton.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":["a.btn{\n    border: 1.5px solid #021027 !important;\n    border-radius: 5px;\n    color:#021027;\n}\n\na.btn:hover{\n    background-color: #021027;\n    color:white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
